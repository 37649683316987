<script>
    import simplebar from "simplebar-vue";
    import helper from "@/assets/helpers/helper";
    import Edit from "@/components/popups/edit";

    export default {
        name: "postPage",
        components: {Edit, simplebar},
        data(){
            return{
                post:{
                    my_interaction:[],
                    comments:[],
                    user:{},
                    content:''
                },
                title:'Copy',
                submitted: false,
                content: "",
                selectedPost:'',
                page_comm:1,
                post_id:'',
                company_id:'',
                comment_id:'',
                selectedType:'',
                interactions: [
                    {
                        id:1,
                        title:'like',
                        icon:'bx bxs-like text-primary',

                    },
                    {
                        id:2,
                        title:'happy',
                        icon:'bx bxs-happy-alt text-warning',

                    },
                    {
                        id:3,
                        title:'love',
                        icon:'bx bxs-heart text-danger',

                    },
                    {
                        id:4,
                        title:'shocked',
                        icon:'bx bxs-shocked text-warning',

                    },
                    {
                        id:5,
                        title:'sad',
                        icon:'bx bxs-sad text-warning',

                    },

                ],
                type:'',
                sharing: {
                    url: this.$parent_stream+'/posts/'+this.$route.params.id,
                  title: 'Check out this post on CRISIS \n'+this.$parent_stream+'/posts/'+this.$route.params.id,
                  description: 'Check out this post on CRISIS \n'+this.$parent_stream+'/posts/'+this.$route.params.id,
                  quote: 'Check out this post on CRISIS \n'+this.$parent_stream+'/posts/'+this.$route.params.id,
                    hashtags: 'CRISI5',
                    twitterUser: 'CRISI5'
                },
                networks: [
                    { network: 'facebook', name: 'Facebook', icon: 'fab fah fa-lg fa-facebook-f', color: '#1877f2' },
                    { network: 'linkedin', name: 'LinkedIn', icon: 'fab fah fa-lg fa-linkedin', color: '#007bb5' },
                    { network: 'twitter', name: 'Twitter', icon: 'fab fah fa-lg fa-twitter', color: '#1da1f2' },
                ]
            }
        },
        created(){
            this.loadPost();
        },
        methods:{
            async loadPost(){
                this.post =  await helper.getAll('posts/'+this.$route.params.id);
                this.post.comments = this.post.comments.reverse()
                this.sharing.description = this.post.content
                this.sharing.title = this.post.content
                this.sharing.quote = this.post.content

            },
            async myComment(){
                this.content =  document.getElementById("my_cmt").innerText
                    let r = await helper.postReturnData('posts/'+this.post.id+'/comments',{'content':this.content},'s')
                    if(r) {
                        r.user = this.$store.state.user
                        this.post.comments_count =  this.post.comments_count + 1
                        this.post.comments.unshift(r)
                        document.getElementById("my_cmt").innerText = ""
                        this.content=""
                    }

            },
            showEdit(post,type,comment_id) {
                this.selectedPost = post
                this.post_id = this.post.id;
                this.comment_id = comment_id;
                this.selectedType = type;

                this.$root.$emit('bv::show::modal', 'modal-edit')
            },
            onCopy: function () {
                setTimeout(() => {
                    this.title = 'Copied';
                }, 200);
                this.title = 'Copy';

            },
            async loadComments(){
                let comments = await helper.loadData('posts/'+this.post.id+'/comments', this.page_comm+1);
                if(comments){
                    this.page_comm = this.page_comm+1
                    this.post.comments.push(comments.data)
                }

            },
            async unsavePost(){
                await helper.postData('posts/'+this.post.id+'/unsave')
            },
            async intract(i){
                let  a = await helper.postReturnData('posts/'+this.post.id+'/interactions',{'relation':i})
               if (!a) {
        this.post.interactions_count = this.post.interactions_count - 1;
        this.post.my_interaction = [];
      } else {
        if (!this.post.my_interaction[0]) this.post.interactions_count = this.post.interactions_count + 1;
        this.$set(this.post.my_interaction, 0, a);
      }
            },
            refresh(content){
                if(this.selectedType == 'posts') this.post.content = content
                if(this.selectedType == 'comments') {
                    this.post.comments.forEach(element => {
                        if(element.id == this.comment_id) element.content = content
                    });

                }
            }
        }
    }
</script>
<template>
    <div class="row">
        <div class="col-xl-8 m-auto">
            <div class="card" >
                <div class="card-body">
                    <div class="media" >
                        <b-avatar :src="post.user.avatar"    class="mr-4 "></b-avatar>
                      <div class="media-body overflow-hidden" v-if="post.user_id">
                        <h5><router-link class="h5 text-truncate font-size-15 link" :to="{name:'ProfilUser',params:{id:post.user_id}}">{{post.user.username}}</router-link></h5>
                        <p class="text-muted">{{post.time_diff}}.</p>
                      </div>
                      <div class="media-body overflow-hidden" v-else>
                        <b-skeleton class="mb-2" width="25%"></b-skeleton>
                        <b-skeleton class="mb-2" width="25%"></b-skeleton>
                      </div>

                        <b-dropdown class="float-right ml-2" variant="white" right toggle-class="text-muted">
                            <template v-slot:button-content>
                                <i class="mdi mdi-dots-horizontal font-size-18"></i>
                            </template>
                            <b-dropdown-item @click="unsavePost">{{$t('post.hide')}}</b-dropdown-item>
                            <b-dropdown-item @click="showEdit(post.content,'posts')" v-if="post.is_my_post">{{$t('post.edit')}}</b-dropdown-item>
                        </b-dropdown>

                    </div>


                  <div v-if="!post.content">
                    <b-skeleton class="my-2" width="100%"></b-skeleton>
                    <b-skeleton class="mb-2"  width="80%"></b-skeleton>
                    <b-skeleton class="mb-2"  width="50%"></b-skeleton>
                  </div>
                  <p class="text-muted" v-else>{{post.content}}</p>

                    <hr>
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="like-block position-relative d-flex align-items-center">
                            <div class="d-flex align-items-center">
                                <div class="like-data">
                                         <span class="dropdown-toggle" :id="'tooltip'+post.id" aria-haspopup="true" aria-expanded="true" role="button">
                                                <i class="interactions" :class="post.my_interaction.length != 0
                      ? `${interactions[post.my_interaction[0].relation - 1].icon}`
                      : 'bx bxs-like'"></i>
                                         </span>
                                    <b-tooltip :target="'tooltip'+post.id" placement="bottom" triggers="hover">
                                        <i class="interactions" v-for="inter of interactions" :key="inter.id"
                                           :class="inter.icon" :title="inter.title"  @click="intract(inter.id)"></i>
                                    </b-tooltip>
                                </div>

                                <div class="total-like-block ml-2 mr-3">{{post.interactions_count}}</div>
                            </div>
                            <div class="total-comment-block">{{post.comments_count}} {{$t('post.comments')}}</div>
                        </div>
                        <div class="share-block d-flex align-items-center feather-icon mr-3">
                            <b-dropdown class="float-right ml-2" variant="white" right toggle-class="text-muted">
                                <template v-slot:button-content>
                                    <i class="fas fa-share mr-2"></i> <span class="link">{{$t('post.share')}}</span>
                                </template>
                                <ShareNetwork class="dropdown-item"
                                              v-for="network in networks"
                                              :network="network.network"
                                              :key="network.network"
                                              :style="{color: network.color}"
                                              :url="sharing.url"
                                              :title="sharing.title"
                                              :description="sharing.description"
                                              :quote="sharing.quote"
                                              :hashtags="sharing.hashtags"
                                              :twitterUser="sharing.twitterUser">
                                    <i :class="network.icon"></i>
                                    <span class="ml-3">{{ network.name }}</span>
                                </ShareNetwork>
                                <b-dropdown-item v-b-tooltip.hover.top :title="title"
                                                 v-clipboard:copy="sharing.url"
                                                 v-clipboard:success="onCopy" alt="Copy to clipboard">
                                    <i class="bx bx-paste font-size-16 align-middle mr-1"></i>
                                    {{$t('post.copy')}}
                                </b-dropdown-item>

                            </b-dropdown>
                        </div>
                    </div>
                    <hr>
                    <b-card-text class="d-none d-md-block">

                        <simplebar style="max-height: 380px;padding-right: 12px">
                            <ul class="list-unstyled chat-list">
                                <li class v-for="(data,index) of post.comments" :key="data.id">

                                    <div class="media" >
                                        <b-avatar :src="data.user.avatar"  v-if="data.user.avatar"  class="mr-4 "></b-avatar>

                                        <b-avatar v-else variant="primary" :text="(data.user.username).substr(0, 1)" class="align-baseline mr-4"></b-avatar>


                                        <div class="media-body overflow-hidden">
                                          <h5><router-link class="h5 text-truncate p-0 border-0 font-size-13 link" :to="{name:'ProfilUser',params:{id:data.user_id}}">{{data.user.username}}</router-link></h5>
                                            <p class="text-muted">{{data.time_diff}}</p>
                                            <p class="mb-1">{{ data.content }}</p>
                                        </div>

                                        <i class="float-right mt-1 fa fa-edit fa-1x link" v-if="data.is_my_comment" @click="showEdit(data.content,'comments', data.id)" ></i>
                                    </div>

                                    <hr v-if="index != post.comments.length - 1">
                                </li>
                            </ul>
                            <div class="row mt-1" v-if="post.comments.length >=2">
                                <div class="col-12">
                                    <div class="text-center">
                                        <span class="text-success link" @click="loadComments">{{$t('post.loadmore')}} </span>
                                    </div>
                                </div>
                            </div>
                        </simplebar>

                    </b-card-text>
                    <div class="pt-3 chat-input-section d-none d-md-block" >
                        <form  class="row">
                            <div class="col-xl-11 m-auto">
                                <span  id="my_cmt" class="t form-control pr-5" role="textbox" style="border-radius: 30px" contenteditable></span>

                                    <div class="chat-input-links">
                                        <ul class="list-inline mb-0">
                                            <li class="list-inline-item">
                                                <a href="javascript: void(0);" v-b-tooltip.hover placement="top"
                                                   title="Send" @click="myComment">
                                                    <i class="mdi mdi-send"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                        </form>
                    </div>

                </div>
                <edit :content="selectedPost" :post_id="post_id" :type="selectedType" :company_id="company_id" :comment_id="comment_id" v-on:refresh="refresh"/>
            </div>
        </div>
    </div>

</template>

<style scoped>
    i.interactions {
        font-size: 24px !important;
        margin-right: 6px !important;
        cursor: pointer !important;
    }
    .t {
        display: block;
        width: 100%;
        overflow: hidden;
        resize: none;
        min-height: 40px;
        height: auto;
        line-height: 20px;
    }

    .t[contenteditable]:empty::before {
        content: "Enter Message...";
        color: gray;
    }

</style>